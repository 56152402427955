<!--
 * @Author: zzw131 750865560@qq.com
 * @Date: 2024-08-02 08:38:29
 * @LastEditors: zzw131 750865560@qq.com
 * @LastEditTime: 2024-10-14 13:35:13
 * @FilePath: /rio/Users/zouzhiwei/Desktop/myvue2/myvue/src/views/portfolioView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <img class="bg-gradient" src="../bootstrap/img/gradient2.png" alt="">
  <div class="bg-noise"></div>
  <div class="bgyanse"></div>

  <a href="https://123.56.182.139:8888/down/hDPC3mO1wybb.pdf"><el-button @click="openCenter" style=" position: fixed;
  bottom: 40px;
  right: 60px;
  z-index: 999;" type="primary">下载作品集</el-button></a>



  <nav class="navbar  nav">
    <a class="navbar-brand" href="/">
      <img src="../bootstrap/img/tuzi.png" alt="Logo" width="30" height="30">
      首页
    </a>
  </nav>
  <div class="col tishi">手机端建议横屏观看</div>

  <div style="margin: 0  auto;" class="row d-flex  align-items-center ">
    <div class="col-2 d-flex justify-content-center">
      <button class="btn btn-outline-dark tiaozhanbtn_Prev " id="customPrevButton">←</button>
    </div>
    <div class="col-8">
      <swiper :style="{
        '--swiper-navigation-color': '#000000',
        '--swiper-pagination-color': '#000000',
      }" :pagination="{ type: 'custom' }" 
      :navigation="{
      hiddenClass: 'swiper-button-hidden',
      nextEl: '#customNextButton',
      prevEl: '#customPrevButton'
    }" :mousewheel="true" :spaceBetween="10" :thumbs="{ swiper: thumbsSwiper }" :modules="modules"
        class="mySwiper2">


        <swiper-slide><img class="img" src="../bootstrap/img/fm1.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fm2.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fm3.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fm4.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fm5.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx1.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx2.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx3.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx4.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx5.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx6.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx7.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx8.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx9.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx10.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx11.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/hx12.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs1.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs2.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs3.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs4.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs5.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs6.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs7.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs8.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs9.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs10.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs11.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/ybs12.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy1.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy2.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy3.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy4.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy5.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy6.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy7.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy8.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy9.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy10.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy11.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy12.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy13.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy14.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/sy15.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb1.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb2.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb3.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb4.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb5.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb6.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb7.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb8.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb9.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb10.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb11.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb12.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb13.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb14.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb15.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb16.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/fb17.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy1.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy2.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy3.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy4.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy5.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy6.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy7.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy8.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy9.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy10.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy11.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy12.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy13.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy14.png" alt=""></swiper-slide>
        <swiper-slide><img class="img" src="../bootstrap/img/yy15.png" alt=""></swiper-slide>



      </swiper>




    </div>
    <div class="col-2 d-flex justify-content-center">
      <button class="btn btn-outline-dark tiaozhanbtn_Next" id="customNextButton">→</button>
    </div>
  </div>

  <div class="col">
    <swiper @swiper="setThumbsSwiper" :spaceBetween="10" :slidesPerView="7" :freeMode="true" :watchSlidesProgress="true"
      :modules="modules" class="mySwiper3">

      <swiper-slide><img class="img" src="../bootstrap/img/fm1.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fm2.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fm3.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fm4.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fm5.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx1.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx2.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx3.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx4.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx5.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx6.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx7.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx8.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx9.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx10.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx11.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/hx12.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs1.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs2.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs3.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs4.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs5.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs6.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs7.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs8.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs9.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs10.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs11.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/ybs12.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy1.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy2.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy3.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy4.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy5.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy6.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy7.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy8.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy9.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy10.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy11.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy12.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy13.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy14.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/sy15.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb1.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb2.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb3.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb4.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb5.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb6.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb7.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb8.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb9.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb10.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb11.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb12.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb13.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb14.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb15.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb16.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/fb17.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy1.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy2.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy3.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy4.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy5.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy6.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy7.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy8.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy9.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy10.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy11.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy12.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy13.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy14.png" alt=""></swiper-slide>
      <swiper-slide><img class="img" src="../bootstrap/img/yy15.png" alt=""></swiper-slide>

    </swiper>
  </div>


  <!-- 按钮 -->




</template>


<script>

// swiper 插件
import { Swiper, SwiperSlide } from 'swiper/vue';

import { FreeMode, Autoplay, Pagination, Navigation, Virtual, Mousewheel, Thumbs } from 'swiper/modules';

// swiper 插件样式
import { ref } from 'vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import 'swiper/css/virtual';
import 'swiper/css/thumbs';

export default {
  name: 'PortfolioView',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const thumbsSwiper = ref(null);

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };


    return {
      setThumbsSwiper,
      thumbsSwiper,
      modules: [Mousewheel, FreeMode, Autoplay, Pagination, Navigation, Virtual, Thumbs],
    };
  },
  methods: {
    
    handleNextButtonClick() {
      console.log('Next button clicked');},



    openCenter() {
      this.$message({
        message: '下载成功（如若弹出安全报错请放心下载个人服务器无安全隐患）',
        center: true,
        type: 'success'
      });
    }
  }
};
</script>
<style scoped>
.mySwiper2 {
  border-radius: 10px;
  height: auto;
  width: 90%;
  margin-top: 0;
  /* 黑色外描边 */
  outline: 8px solid rgba(0, 0, 0, 0.877);
  /* 黑色外投影 */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.233);
}

.mySwiper3 {

  height: 10%;
  box-sizing: border-box;
  margin: 20px 0 0 0;
}

.mySwiper3 .swiper-slide {
  width: 10%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper3 .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .tiaozhanbtn_Next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* 或者 50% */
    font-size: 12px;
    /* 或者 50% */
  }

  .tiaozhanbtn_Prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* 或者 50% */
    font-size: 12px;

  }

  .nav {
    margin: 0;
    padding-left: 30%;
    z-index: 999;
  }

  .tishi {
    font-size: 6vw;
    text-align: center;
    padding-bottom: 20px;

  }

}




@media screen and (min-width: 769px) {
  .tiaozhanbtn_Next {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* 或者 50% */
    font-size: 30px;
    /* 或者 50% */
  }

  .tiaozhanbtn_Prev {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* 或者 50% */
    font-size: 30px;

  }

  ::v-deep.swiper-pagination-bullet {
    width: 500px;
    height: 12px;
    border-radius: 50%;
    background-color: pink;
  }

  ::v-deep.swiper-pagination-bullet-active {
    background-color: orange;
  }

  .tishi {
    display: none;
  }

}

.nav {
  margin: 0;
  padding-left: 3%;

  z-index: 999;
}

.body {
  height: 100vh;
  margin: 0;

}

.container-fluid {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}



.swiper-slide {
  background-color: rgba(0, 0, 0, 0);
}

.img {
  object-fit: contain;

}

/* 背景 */
.bg-noise {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-image: url(../bootstrap/img/noise.gif);
  background-position: 0 0, 0 0;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  opacity: .05;
}

.bg-gradient {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100vh;
  background-image: url(../bootstrap/img/gradient2.jpg);
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;

}

.bg-gradient::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  height: 15vh;
  background: linear-gradient(180deg, rgba(236, 239, 248, 0) 11.11%, #EDEFF8 100%);
}

.bgyanse {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f8ff;
  z-index: -3;
  /* 确保在.bg-noise 下面 */
}
</style>